import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom"
import { useLogin } from "../../store/AuthProvider"

import Home from '../Home/Home'
import About from '../About/About'
import Partners2 from '../Partners/Partners2'
import LayoutMenu from "./LayoutMenu"
import Contact from "../../components/Footer/Contact"
import Footer from "../../components/Footer/Footer"
import Plantilla from "../Plantilla/plantilla"
import Login from "../privado/Login"
import Test from "../privado/test"
import Novas from "../novas/novas"
import TapaOtono2022 from "../novas/novasconcretas/tapaOtono2022"

import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'


const Layout = () => {

  return (
    <div className="layout">
      <LayoutMenu />
      <Routes>
        <Route path="/" element={<Home />}  exact />
        <Route path="/about" element={<About />}  exact />
        <Route path="/partners" element={<Partners2 />}  exact />
        <Route path="/:dominio" element={<Plantilla />}  exact />
        <Route path="/novas" element={<Novas />}  exact />
        <Route path="/novas" element={<Novas />}  exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/tapa_otono_22" element={<TapaOtono2022 />} exact /> 
        <Route path="/privado" element={
          <PrivateRoute>
            <Test />
          </PrivateRoute>
        }>
        </Route>
      </Routes>
      <Contact />
      <Footer />
    </div>
  )
}

export default Layout

function PrivateRoute({ children }) {
  const { user } = useLogin()
  const location = useLocation()

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}