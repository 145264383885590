import { useLogin } from '../../store/AuthProvider'
import QTPrivado from "./QTPrivado"

import convocatoria from '../../assets/images/convocatoriaxeral.jpg'

const Test = () => {
  const { user } = useLogin()

  return (
    <><main className="test">
      <QTPrivado />
      <h1>BIENVENIDO</h1>
      <h1>{user.username}</h1>
      <h1>ERES EL ASOCIADO Nº{user.id}</h1>
      <div className="about-section-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
          {/* <object data="../../assets/images/convocatoriaxeral.pdf" height="100%" width="100%"></object>  */}
            {/* <iframe src="files/husky.pdf" height="100%" width="100%"></iframe>  */}
            <img src={convocatoria} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <div className="inner-column">
              <h1>Asamblea xeral<span>HOSTELERIA DE MOAÑA</span></h1>       
              <p>Luns 21 de Febreiro de 2022</p>
              <p>enriba da plaza de abastos</p>
              {/* <Link to='/About' className="btn btn-lg btn-circle btn-outline-new-white">
                        SABER MAIS
                     </Link>  */}
            </div>       
          </div>
        </div>
      </div>
    </div>
    </main>

    </>
  )
}

export default Test