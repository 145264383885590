import { Carousel, CarouselItem } from "../../../components/Carousel"

import image1 from '../../../assets/images/noticias/muxicas.jpg'
import image2 from '../../../assets/images/noticias/piscis.jpg'
import image3 from '../../../assets/images/noticias/cafferia.jpg'
import image4 from '../../../assets/images/noticias/xardin.jpg'
import image5 from '../../../assets/images/noticias/sandro.jpg'
import image6 from '../../../assets/images/noticias/fairies.jpg'
import image7 from '../../../assets/images/noticias/pradoviejo.jpg'
import image8 from '../../../assets/images/noticias/carriola.jpg'
import image9 from '../../../assets/images/noticias/fabrica.jpg'
import image10 from '../../../assets/images/noticias/cibeles.jpg'
import image11 from '../../../assets/images/noticias/rosal.jpg'
import image12 from '../../../assets/images/noticias/equis.jpg'
import image13 from '../../../assets/images/noticias/puerto.jpg'

const NovasTapasCarrousel = () => {
    return (
        <div className="customer-reviews-box">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="heading-title text-center">
                            <h2>IMÁXENES</h2>
                            Presentación dos productos que participan no concurso en imáxenes.
                            Pulsa sobre o nome do local si queres visitar a páxina concreta de ese local
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 mr-auto ml-auto text-center">
                        <Carousel id="reviews" indicators={false}>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image1} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }}>
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="muxicas" title="muxicas"
                                        href="http://hostaleriamoaña.com/MuxicasCervexariaVi%C3%B1oteca"> MUXICAS</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >TAPAS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box m-auto">
                                    <img className="d-block w-100 " src={image2} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong> <a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="piscis" title="piscis"
                                        href="http://hostaleriamoaña.com/Piscis">PISCIS
                                    </a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >TAPAS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image3} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="cafferia" title="cafferia"
                                        href="http://hostaleriamoaña.com/LaCafferia">CAFFERÍA</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0">COCKTAILS & CAFÉS</h6>

                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image4} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="xardin" title="xardin"
                                        href="http://hostaleriamoaña.com/CafeteriaHeladeriaXardin">XARDIN </a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0">COCKTAILS & CAFÉS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image5} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="aDeSandro" title="aDeSandro"
                                        href="http://hostaleriamoaña.com/ADeSandro">A DE SANDRO</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >TAPAS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image6} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="fairies" title="fairies"
                                        href="http://hostaleriamoaña.com/TheFairies">THE FAIRIES</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0">COCKTAILS & CAFÉS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image7} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong className="text-warning text-uppercase">PRADO VIEJO</strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >MENÚ</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image8} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="carriola" title="carriola"
                                        href="http://hostaleriamoaña.com/ACarriola">A CARRIOLA</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0">COCKTAILS & CAFÉS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image9} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="fabrica" title="fabrica"
                                        href="http://hostaleriamoaña.com/LaFabrica">LA FABRICA</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >MENÚ</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image10} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="cibeles" title="cibeles"
                                        href="http://hostaleriamoaña.com/BarTaperiaLaCibeles">LA CIBELES</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >TAPA</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image11} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="oRosal" title="oRosal"
                                        href="http://hostaleriamoaña.com/PanaderiaORosal">PANADERIA O ROSAL</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >COCKTAILS & CAFÉS</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image12} alt="" />
                                </div>
                                <h5 style={{ padding: '150px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="equis" title="equis"
                                        href="http://hostaleriamoaña.com/BarEquis">BAR EQUIS</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >TAPA</h6>
                            </CarouselItem>
                            <CarouselItem>
                                <div className="img-box  m-auto">
                                    <img className="d-block w-100 " src={image13} alt="" />
                                </div>
                                <h5 style={{ padding: '100px 0 0 0' }} className="mt-4 mb-0">
                                    <strong><a className="text-warning text-uppercase" target="_blank" rel="noreferrer" alt="elPuerto" title="elPuerto"
                                        href="http://hostaleriamoaña.com/ArroceriaElPuerto">EL PUERTO</a></strong>
                                </h5>
                                <h6 style={{ padding: '10px 0 0 0' }} className="text-dark m-0" >MENÚ</h6>
                            </CarouselItem>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NovasTapasCarrousel