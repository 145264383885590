/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer-area bg-f">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <Link to="/privado"> <h3>ASOCIADOS</h3></Link>
         
            <p>Desde sus inicios la asociación busca cumplir los derechos de la asociados y las ventajas en varios ámbitos que pueden obtener por ser un grupo grande de autónomos.</p>
          </div>
          <div className="col-lg-3 col-md-6">
          <h3>DIRECTIVA</h3>
            <p><span className="text-color">President@ </span>Melania Pena Sequeiros - Café Bar Xardín.</p>
            <p><span className="text-color">Secretari@ :</span>Mario Cao Otero - The fairies.</p>
            <p><span className="text-color">Tesorer@ :</span>Pablo Piñeiro Costa - A Carriola</p>
            
          </div>
          <div className="col-lg-3 col-md-6">
					<h3>INFORMACION DE CONTACTO</h3>
            <p className="lead">c/Concepción Arenal nº50 bajo Moaña Pontevedra 36950</p>
            <p className="lead"><a href="tel:+34698989820">+34 698 98 98 20</a></p>
            <a href="mailto:hostelerosmoana@gmail.com">hostelerosmoana@gmail.com</a>
          </div>
          <div className="col-lg-3 col-md-6">
					{/* <h3>ASOCIATE</h3>
            <div className="subscribe_form">
              <form className="subscribe_form">
                <input name="EMAIL" id="subs-email" className="form_input" placeholder="Email Address..."
                  type="email" />
                <button type="submit" className="submit">SUBSCRIBE</button>
                <div className="clearfix"></div>
              </form>
            </div> */}
            <ul className="list-inline f-social">
              <li className="list-inline-item"><a href="https://www.facebook.com/hostaleriamoana/" target="_blank" rel="noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></a>
              </li>
              {/* <li className="list-inline-item"><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
              </li>
              <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
              </li>
              <li className="list-inline-item"><a href="#"><i className="fa fa-google-plus"
                    aria-hidden="true"></i></a></li>
              <li className="list-inline-item"><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="company-name">All Rights Reserved. &copy; 2021 <a href="#">hosteleiros Moaña</a>
                Design By :
                FERRUCAN
              </p>
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer