import Header from "../../components/Header/Header"

import logo from '../../assets/images/logo.jpeg'

const LayoutMenu = () => {
  const menu = {
    brand: {
      image: logo,
      to: '/'
    },
  
    links: [
      {
        title: 'Portada',
        to: '/',
      },
      {
        title: 'Asociados',
        to: '/partners',
      },
      {
        title: 'Sobre nos',
        to: '/about'
      },
      {
        title: 'Noticias',
        to: '/novas'
      },
    
      // {
      //   title: 'Blog',
      //   submenu: [
      //     {
      //       title: 'Blog',
      //       to: '/'
      //     },
      //     {
      //       title: 'Blog single',
      //       to: '/'
      //     },
      //   ]
      // }
    ]
  }
  return <Header menu={menu} />
}

export default LayoutMenu